import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from '../index';
import downloadFile from "@/utils/claim/downloadFile";
// page数据接口
export const pageList = (params = {
    programNameCn: '' as string | undefined,
    programNameEn: '' as string | undefined,
    programCode: '' as string | undefined,
    eligiblePeriodFrom: '' as string | undefined,
    eligiblePeriodTo: '' as string | undefined,
    submissionDateFrom: '' as string | undefined,
    submissionDateTo: '' as string | undefined,
    offerTypeIdLst: [] as string[] | undefined,
    programStatusLst: [] as string[] | undefined,
    current: 0,
    size: 20
  }): Promise<any> => {
    /*console.log(params)
    let str = ''
    if(params.programNameCn!=''&&params.programNameCn!=null){
        str += `&programNameCn=${params.programNameCn}`
    }
    if(params.programNameEn!=''&&params.programNameEn!=null){
        str += `&programNameEn=${params.programNameEn}`
    }
    if(params.programCode!=''&&params.programCode!=null){
        str += `&programCode=${params.programCode}`
    }
    if(params.eligiblePeriodFrom!=''&&params.eligiblePeriodFrom!=null){
        str += `&eligiblePeriodFrom=${params.eligiblePeriodFrom}`
    }
    if(params.eligiblePeriodTo!=''&&params.eligiblePeriodTo!=null){
        str += `&eligiblePeriodTo=${params.eligiblePeriodTo}`
    }
    if(params.submissionDateFrom!=''&&params.submissionDateFrom!=null){
        str += `&submissionDateFrom=${params.submissionDateFrom}`
    }
    if(params.submissionDateTo!=''&&params.submissionDateTo!=null){
        str += `&submissionDateTo=${params.submissionDateTo}`
    }
    if(params.offerTypeIdLst!=[]&&params.offerTypeIdLst!=null){
        str += `&offerTypeIdLst=${params.offerTypeIdLst}`
    }
    if(params.programStatusLst!=[]&&params.programStatusLst!=null){
        str += `&programStatusLst=${params.programStatusLst}`
    }*/
    // return fetchGet(`/claimapi/checking/program/page?programNameCn=${params.programNameCn}&programNameEn=${params.programNameEn}&programCode=${params.programCode}&eligiblePeriodFrom=${params.eligiblePeriodFrom}&eligiblePeriodTo=${params.eligiblePeriodTo}&submissionDateFrom=${params.submissionDateFrom}&submissionDateTo=${params.submissionDateTo}&offerTypeIdLst=${params.offerTypeIdLst}&programStatusLst=${params.programStatusLst}&current=${params.current}&size=${params.size}`, params)
    // return fetchGet(`/claimapi/checking/program/page?current=${params.current}&size=${params.size}${str}`, params)
    return fetchPost(`/claimapi/checking/program/page`, params)
}
// OfferType数据接口
export const getOfferType = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/checking/program/getOfferType', params)
    return fetchGet('/claimapi/common/getOfferType', params)
}
// ProgramStatus数据接口
export const getProgramStatus = (params = {groupCode:''}): Promise<any> => {
    return fetchGet('/claimapi/claimDictionary/findByGroupCode?groupCode='+params.groupCode, params)
}
// 获取Assignee数据接口
export const getCoverList = (params = {programCode :'',programCategory:''}): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/detail/reassign/checking/plan?programCode='+params.programCode+'&programCategoryId='+params.programCategory  , params)
}
// 修改Assignee数据接口
export const postCoverList = (params = {programCode :'',offerTypeId:'',assignee:''}): Promise<any> => {
    return fetchPostPic('/claimapi/checking/plan/detail/reassign/checking/plan', params)
}
// 获取neme数据
export const postNoauthUsers = (params = {legalEntity: {entityName: "BMBS"},organization: {entityName: "SICCO"}}): Promise<any> => {
    return fetchPost('/usapi/noAuth/users', params)
}

// 获取列表数据 11661
export const getSelectProgramEnquiry = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/program/selectProgramEnquiry', {params})
}

//根据bu，查checkinground, 11661
export const getCheckingRoundList = (params: any): Promise<any> => {
    return fetchGet('/claimapi/checking/program/checkingRoundList', {params})
}

//导出查询内容 11661
export const exportPageApi = (params = {}): void => {
    const option = {
        url: `/claimapi/checking/program/exportSelectProgram`,
        method: 'post',
        params
    }
    downloadFile(option, 'application/vnd-excel; char-set=UTF-8')
}

//设置数据为checking/closing
export const setClosingStatus= (data = {},state: string): Promise<any> => {
    return fetchPostPic('/claimapi/checking/program/setClosingStatus?status='+state, (data as any).setProgramStatusLst)
}
//设置状态为approve
export const setApproveClosingStatus= (data = {}): Promise<any> => {
    return fetchPostPic('/claimapi/checking/program/approveClosingStatus', (data as any).progDesignIds)
}

