
//http://localhost:8080/#/claim/EnquiryProgram
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    nextTick,
    computed,
} from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import {
    pageList,
    getOfferType,
    getProgramStatus,
} from '@/API/claim/claimEnquiryProgram';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import EditEnquiry from '../EnquiryProgram/EditEnquiryProgram.vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import { useStore } from 'vuex';
import moment from 'moment';
import SelectProgram from './SelectProgram.vue';
import { getUser } from '@/API/claim/delaerClaim/delaer';
// import axios from "axios";
const tableWidth = window.innerWidth;
const columns = reactive([
    { title: 'Program Code', dataIndex: 'programCode', width: 140 },
    { title: 'Program Name(EN)', dataIndex: 'programNameEn', width: 210 },
    { title: 'Program Name(CN)', dataIndex: 'programNameCn', width: 210 },
    { title: 'Program Category', dataIndex: 'offerTypeNameEn', width: 180 },
    {
        title: 'Program Period /Submission Period',
        dataIndex: 'programSubmissionPeriodLst',
        width: 330,
        slots: { customRender: 'Periods' },
    },
    { title: 'Program Status', dataIndex: 'programStatusEn', width: 130 },
    { title: 'Claim Qty', dataIndex: 'claimQty', width: 90 },
    { title: 'Unchecked Qty', dataIndex: 'uncheckedQty', width: 120 },
    { title: 'Unchecked Doc Qty', dataIndex: 'uncheckedDocQty', width: 170 },
    { title: 'Assignee', dataIndex: 'assignee', width: 100 },
    {
        title: 'Operation',
        dataIndex: 'checkResult',
        width: 130,
        slots: { customRender: 'operation' },
    },
]);
interface QueryListType {
    programNameCn: string | null;
    programNameEn: string | null;
    programCode: string | null;
    offerTypeIdLst: [];
    eligiblePeriod: string[];
    submissionDate: string[];
    programStatusLst: [];
}
interface SearchListType {
    dealerCode: string | null;
    model: string | null;
    checkResult: string | null;
    eligiblePeriodQueryFrom: string | null;
    eligiblePeriodQueryTo: string | null;
    dealerNameCn: string | null;
    inParagraph: string | null;
    complaintStatus: string | null;
    createdAtQueryFrom: string | null;
    createdAtQueryTo: string | null;
    current: number | 0;
    size: number | 20;
}
interface TableType {
    dealerCode: string;
    dealerNameCn: string;
    programCode: string;
    eligiblePeriodStr: string;
    offerTypeName: string;
    optionName: string;
    vinNo: string;
    model: string;
    inParagraph: string;
    payRound: string;
    checkResult: string;
    complaintStatus: string;
    predictAmount: string;
    price: string;
    createdTime: string;
    status: string;
}
export default defineComponent({
    components: {
        EditEnquiry,
        SelectProgram,
    },
    setup() {
        const { state } = useStore();
        const user = (state.user as any).organization.entityCode;
        const isSICCO = user === 'SICCO' ? true : false;
        const searchCon = ref();
        const tableHeight = ref();
        // 检查状态list值
        const checkResultList = reactive<object>([
            { id: 1, name: 'Approve' },
            { id: 2, name: 'Reject' },
            { id: 3, name: 'Pending' },
            { id: 4, name: 'Unchecked' },
        ]);
        // 年款假值
        const inParagraphList = reactive<object>([
            { id: 1, name: '1' },
            { id: 2, name: '2' },
            { id: 3, name: '3' },
            { id: 4, name: '4' },
        ]);
        // 申诉状态
        const complaintStatusList = reactive<object>([
            { id: 1, name: 'dealer已提交' },
            { id: 2, name: '已关闭' },
        ]);
        //筛选条件，各个信息框的内容
        const queryListParams = reactive({
            programNameCn: '',
            programNameEn: '',
            programCode: '',
            offerTypeIdLst: [],
            eligiblePeriod: [],
            submissionDate: [],
            programStatusLst: [],
        });
        // 设置搜索值
        const params = reactive<SearchListType>({
            dealerCode: '',
            model: '',
            checkResult: '',
            eligiblePeriodQueryFrom: '',
            eligiblePeriodQueryTo: '',
            dealerNameCn: '',
            inParagraph: '',
            complaintStatus: '',
            createdAtQueryFrom: '',
            createdAtQueryTo: '',
            current: 0,
            size: 20,
        });
        //显示的表格数据
        const queryListData = ref<TableType[]>([]);
        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });
        //定义初始值
        const listParams = reactive({
            current: 0,
            size: 20,
            offerTypeIdLst: [] as string[] | undefined,
            programStatusLst: [] as string[] | undefined,
            programNameCn: '' as string | undefined,
            programNameEn: '' as string | undefined,
            programCode: '' as string | undefined,
            eligiblePeriodFrom: '' as string | undefined,
            eligiblePeriodTo: '' as string | undefined,
            submissionDateFrom: '' as string | undefined,
            submissionDateTo: '' as string | undefined,
            buLst: ['0'],
        });
        const buIDArray = ref<string[]>([]);
        // 获取params
        const getParams = () => {
            listParams.programNameCn = '';
            listParams.programNameEn = '';
            listParams.programCode = '';
            listParams.eligiblePeriodFrom = '';
            listParams.eligiblePeriodTo = '';
            listParams.submissionDateFrom = '';
            listParams.submissionDateTo = '';
            listParams.offerTypeIdLst = [];
            listParams.programStatusLst = [];
            listParams.current = pagination.currentPage;
            listParams.size = pagination.pageSize;
            listParams.buLst = buIDArray.value;

            queryListParams.programNameCn !== null &&
            queryListParams.programNameCn !== ''
                ? (listParams.programNameCn = queryListParams.programNameCn)
                : delete listParams.programNameCn;
            queryListParams.programNameEn !== null &&
            queryListParams.programNameEn !== ''
                ? (listParams.programNameEn = queryListParams.programNameEn)
                : delete listParams.programNameEn;
            queryListParams.programCode !== null &&
            queryListParams.programCode !== ''
                ? (listParams.programCode = queryListParams.programCode)
                : delete listParams.programCode;
            // queryListParams.eligiblePeriodFrom !== null && queryListParams.eligiblePeriodFrom !== '' ? listParams.eligiblePeriodFrom = queryListParams.eligiblePeriodFrom : ''
            // queryListParams.eligiblePeriodTo !== null && queryListParams.eligiblePeriodTo !== '' ? listParams.eligiblePeriodTo = queryListParams.eligiblePeriodTo : ''
            // queryListParams.submissionDateFrom !== null && queryListParams.submissionDateFrom !== '' ? listParams.submissionDateFrom = queryListParams.submissionDateFrom : ''
            // queryListParams.submissionDateTo !== null && queryListParams.submissionDateTo !== '' ? listParams.submissionDateTo = queryListParams.submissionDateTo : ''

            if (queryListParams.offerTypeIdLst.length > 0) {
                listParams.offerTypeIdLst = queryListParams.offerTypeIdLst;
            } else {
                delete listParams.offerTypeIdLst;
            }
            if (queryListParams.programStatusLst.length > 0) {
                listParams.programStatusLst = queryListParams.programStatusLst;
            } else {
                delete listParams.programStatusLst;
            }
            if (queryListParams.eligiblePeriod.length > 0) {
                listParams.eligiblePeriodFrom =
                    queryListParams.eligiblePeriod[0];
                listParams.eligiblePeriodTo = queryListParams.eligiblePeriod[1];
            } else {
                delete listParams.eligiblePeriodFrom;
                delete listParams.eligiblePeriodTo;
            }
            if (queryListParams.submissionDate.length > 0) {
                listParams.submissionDateFrom =
                    queryListParams.submissionDate[0];
                listParams.submissionDateTo = queryListParams.submissionDate[1];
            } else {
                delete listParams.submissionDateFrom;
                delete listParams.submissionDateTo;
            }
        };
        const handleSelectPC = (value: []) => {
            queryListParams.offerTypeIdLst = value;
        };
        const handleSelectPS = (value: []) => {
            queryListParams.programStatusLst = value;
        };
        const handleSelectPP = (value: []) => {
            console.log(value);
            queryListParams.eligiblePeriod = value;
        };
        const handleSelectSP = (value: []) => {
            queryListParams.submissionDate = value;
        };
        //根据筛选框信息，查询表格
        const getTableData = () => {
            getParams();
            console.log(listParams);
            pageList(listParams).then((res) => {
                queryListData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        getUser().then((res) => {
            for (let i = 0; i < res.buList.length; i++) {
                const bu = res.buList[i];
                buIDArray.value.push(bu.id);
            }
            getTableData();
        });
        // getTableData();
        const inputOfferTypeIdLst = ref<[]>();
        const inputProgramStatusLst = ref<[]>();
        const offertypeList = ref<[]>();
        const programStatusList = ref<[]>();
        const inputProgramNameCn = ref<string>('');
        const inputProgramNameEn = ref<string>('');
        const inputProgramCode = ref<string>('');
        const inputEligiblePeriodFrom = ref<string>('');
        const inputEligiblePeriodTo = ref<string>('');
        //获取offertype下拉框
        const getOfferTypeList = () => {
            getOfferType().then((res) => {
                // console.log(res)
                offertypeList.value = res;
            });
        };
        getOfferTypeList();
        //获取programstatus下拉框
        const getProgramStatusList = () => {
            getProgramStatus({ groupCode: 'program_status' }).then((res) => {
                // console.log(res)
                programStatusList.value = res;
            });
        };
        getProgramStatusList();
        const clearQueryListParams = () => {
            queryListParams.programNameCn = '';
            queryListParams.programNameEn = '';
            queryListParams.programCode = '';
            queryListParams.offerTypeIdLst = [];
            queryListParams.eligiblePeriod = [];
            queryListParams.submissionDate = [];
            queryListParams.programStatusLst = [];
            pagination.pageSize = 20;
            pagination.currentPage = 1;
            pagination.total = 0;
        };
        //更改分页组件，包括：直接跳转到指定页，上一页下一页
        const changePagination = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page - 1 >= 1 ? page : 1;
            getTableData();
        };
        //更改每页size
        const changePageSize = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page - 1 >= 1 ? page : 1;
            getTableData();
        };
        // 删除数据接口调用
        const deleteHandle = (record: any) => {
            const fromDate = new Date(record.submissionDateFrom).getTime();
            const toDate = new Date(record.submissionDateTo).getTime();
            const now = new Date().getTime();
            if (now >= fromDate && now <= toDate) {
                const id = record.id;

                Modal.confirm({
                    title: '确认',
                    content: '确定要删除这条支持文档吗？',
                    okText: '确认',
                    onOk() {
                        deleteInfo(id).then((res) => {
                            if (res.code === '0') {
                                message.success(res.message);
                                getTableData();
                            } else {
                                Modal.error({
                                    title: 'Tips',
                                    content: res.message,
                                    centered: true,
                                });
                            }
                        });
                    },
                    cancelText: '取消',
                });
            } else {
                Modal.error({
                    title: 'Tips',
                    content: '项目已经过截止提交日期，不能删除！',
                    centered: true,
                });
            }
        };
        // ressign接口调用
        const ressignHandle = (record: any) => {
            console.log(record);
            Modal.confirm({
                title: '确认',
                content: '确定要删除这条支持文档吗？',
                okText: '确认',
                onOk() {
                    /*deleteInfo(id).then((res) => {
            if (res.code === "0") {
              message.success(res.message);
              getTableData();
            } else {
              Modal.error({
                title: "Tips",
                content: res.message,
                centered: true,
              });
            }
          });*/
                },
                cancelText: '取消',
            });
            /*const fromDate = new Date(record.submissionDateFrom).getTime();
      const toDate = new Date(record.submissionDateTo).getTime();
      const now = new Date().getTime();
      if (now >= fromDate && now <= toDate) {
        const id = record.id;

        Modal.confirm({
          title: '确认',
          content: '确定要删除这条支持文档吗？',
          okText: '确认',
          onOk() {
            deleteInfo(id).then((res) => {
              if (res.code === "0") {
                message.success(res.message);
                getTableData();
              } else {
                Modal.error({
                  title: "Tips",
                  content: res.message,
                  centered: true,
                });
              }
            });
          },
          cancelText: '取消',
        });

      } else {
        Modal.error({
          title: "Tips",
          content: '项目已经过截止提交日期，不能删除！',
          centered: true,
        });
      }*/
        };
        const exportResult = () => {
            getParams();
            const timeStr = moment(new Date()).format('YYYYMMDDhhmmss');
            console.log(timeStr);
            const param = {
                offerTypeIdLst: listParams.offerTypeIdLst,
                programStatusLst: listParams.programStatusLst,
                programNameCn: listParams.programNameCn,
                programNameEn: listParams.programNameEn,
                programCode: listParams.programCode,
                eligiblePeriodFrom: listParams.eligiblePeriodFrom,
                eligiblePeriodTo: listParams.eligiblePeriodTo,
                submissionDateFrom: listParams.submissionDateFrom,
                submissionDateTo: listParams.submissionDateTo,
                buLst: listParams.buLst,
            };
            const paramsConfig = {
                url: `/claimapi/checking/program/export`,
                method: 'post',
                params: param,
                fileName: `Export_Program_Enquiry_${timeStr}.xlsx`,
            };
            downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
        };
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight -
                220 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 110;
        });

        const isUploadVisible = ref<boolean>(false);
        const docId = ref<string>('');
        const editProgramCode = ref<string>('');
        const editOfferTypeId = ref<string>('');
        const editofferTypeNameEn = ref<string>('');
        const editAssignee = ref<string>('');
        //表格detail操作
        const handlRessign = (record: any) => {
            isUploadVisible.value = true;
            editProgramCode.value = record.programCode
                ? record.programCode
                : '';
            editOfferTypeId.value = record.offerTypeId
                ? record.offerTypeId
                : '';
            editofferTypeNameEn.value = record.offerTypeNameEn
                ? record.offerTypeNameEn
                : '';
            editAssignee.value = record.assignee ? record.assignee : '';
        };
        //关闭detail抽屉操作
        const closeSub = (param: {
            isUploadVisible: boolean;
            isrefresh: boolean;
        }) => {
            isUploadVisible.value = param.isUploadVisible;
            console.log('param.isrefresh', param.isrefresh);
            if (param.isrefresh) {
                getTableData();
            }
        };

        const selectProgramDom = ref<any>(null);
        //
        const setClosingOrApprove = (type: string) => {
            console.log(type);
            selectProgramDom.value.showDrawer(type);
        };

        return {
            selectProgramDom,
            setClosingOrApprove,
            isSICCO,
            editAssignee,
            handleSelectSP,
            handleSelectPP,
            handleSelectPS,
            handleSelectPC,
            getProgramStatusList,
            programStatusList,
            offertypeList,
            inputOfferTypeIdLst,
            inputProgramStatusLst,
            inputProgramNameCn,
            inputProgramNameEn,
            inputProgramCode,
            inputEligiblePeriodFrom,
            inputEligiblePeriodTo,
            getOfferTypeList,
            editofferTypeNameEn,
            editOfferTypeId,
            editProgramCode,
            closeSub,
            docId,
            isUploadVisible,
            handlRessign,
            searchCon,
            tableWidth,
            tableHeight,
            columns,
            checkResultList,
            inParagraphList,
            complaintStatusList,
            queryListParams,
            queryListData,
            params,
            getParams,
            getTableData,
            deleteHandle,
            ressignHandle,
            exportResult,
            pagination,
            changePagination,
            changePageSize,
            // isDisabled,
            clearQueryListParams,
        };
    },
});
