
import { defineComponent, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { docEdit } from '@/API/claim/claimDocumentSample';
import {
    getCoverList,
    postCoverList,
    postNoauthUsers,
} from '@/API/claim/claimEnquiryProgram';

const columns = reactive([
    { title: 'User Name', dataIndex: 'username' },
    {
        title: 'Operation',
        dataIndex: 'checkResult',
        width: 130,
        slots: { customRender: 'operation' },
    },
]);
interface TableType {
    username: string;
}

export default defineComponent({
    components: {},
    emits: ['close-sub-visible', 'get-table-data'],
    props: {
        isUploadVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        programCode: {
            type: String,
            required: false,
            default: '',
        },
        offerTypeId: {
            type: String,
            required: false,
            default: '',
        },
        offerTypeNameEn: {
            type: String,
            required: false,
            default: '',
        },
        assignee: {
            type: String,
            required: false,
            default: '',
        },
    },
    setup(props, context) {
        const docId = (props as any).docId;
        const programCode = (props as any).programCode;
        const offerTypeId = (props as any).offerTypeId;
        const offerTypeNameEn = (props as any).offerTypeNameEn;
        const assignees = (props as any).assignee;
        const usersLst = ref<any[]>([]);
        // 文件展示框是否显示，它显示，上传功能就隐藏
        const editData = reactive<any>({
            id: docId,
            programCode: programCode,
            offerTypeId: offerTypeId,
            offerTypeNameEn: offerTypeNameEn,
        });
        const queryListData = ref<TableType[]>([]);
        const dataArray = ref<[]>([]);
        postNoauthUsers().then((res) => {
            usersLst.value = res
                .filter((item: any) => {
                    return item.state;
                })
                .map((ele: any) => ele.usrnm);

            // const userArr = ref<any>([]);
            // if (res && res.length > 0) {
            //     res.forEach((i: any, ind: any) => {
            //         let isRepeat = false;
            //         userArr.value.forEach((item: any, index: any) => {
            //             if (i.usrnm === item) {
            //                 isRepeat = true;
            //             }
            //         });
            //         if (!isRepeat) {
            //             userArr.value.push(i.usrnm);
            //         }
            //     });
            // }
            // usersLst.value = userArr.value;
        });
        const dogetlistData = () => {
            // console.log(editData.programCode)
            // console.log(editData.offerTypeId)
            console.log(assignees);
            const datalst = [];
            const assigneeLst = assignees ? assignees.split(',') : [];
            for (const i of assigneeLst) {
                datalst.push({ username: i });
            }
            console.log(datalst);
            queryListData.value = datalst;
            /*getCoverList({programCode: editData.programCode, programCategory: editData.offerTypeId}).then(res => {
        console.log(res.data)
        if (res.data.length>0){
          // dataArray.value = res.data
          const datalst = []
          for(const i of res.data){
            datalst.push({username: i})
          }
          console.log(datalst)
          queryListData.value = datalst
        }
      })*/
            // queryListData.value = [{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'}]
        };
        dogetlistData();
        const dogetSelectData = () => {
            /*getCoverList({programCode: editData.programCode, programCategory: editData.offerTypeId}).then(res => {
              console.log(res.data)
              if (res.data.length>0){
                  // dataArray.value = res.data
                  const datalst = []
                  for(const i in res.data){
                      datalst.push({
                          user: res.data[i],
                          key:`${i}`
                      })
                  }
                  console.log(datalst)
                  usersLst.value = datalst
              }
          })*/
            usersLst.value = [
                {
                    user: 'SHICHENG',
                    key: '1',
                },
                {
                    user: 'TIANTAO',
                    key: '2',
                },
                {
                    user: 'JIAPENG',
                    key: '3',
                },
                {
                    user: 'XILIANG',
                    key: '4',
                },
                {
                    user: 'ZHONGYI',
                    key: '5',
                },
                {
                    user: 'ZHENGDA',
                    key: '6',
                },
                {
                    user: 'SICHENG',
                    key: '7',
                },
                {
                    user: 'XUDONG',
                    key: '8',
                },
                {
                    user: 'RONGRONG',
                    key: '9',
                },
            ];
        };
        // dogetSelectData()
        //delete按钮
        const handleDelete = (rec: any) => {
            console.log(queryListData.value.length);
            const newArr = ref<any[]>([]);
            queryListData.value.forEach((item, index) => {
                if (item.username !== rec.username) {
                    newArr.value.push(item);
                }
            });
            queryListData.value = newArr.value;
        };
        const nowName = ref<any[]>([]);
        //add按钮
        const handleAdd = () => {
            let isrepeat = false;
            let isrepeadAll = false;
            const addArr = ref<any[]>([]);
            nowName.value.forEach((nameItem, nameIndex) => {
                queryListData.value.forEach((lstItem, lstIndex) => {
                    if (nameItem === lstItem.username) {
                        isrepeat = true;
                        return false;
                    }
                });
                if (!isrepeat) {
                    addArr.value.unshift({ username: nameItem });
                } else {
                    isrepeadAll = true;
                }
                isrepeat = false;
                // queryListData.value.unshift({username: nameItem})
            });
            if (!isrepeadAll) {
                const concatArr = addArr.value.concat(queryListData.value);
                queryListData.value = concatArr;
                console.log(concatArr);
                nowName.value = [];
            } else {
                message.error('Current assignee has already exists.');
            }
        };
        // // 设置抽屉显示状态
        const modal = (props as any).isUploadVisible;
        const handleSelect = (val: any) => {
            nowName.value = val;
            console.log(val);
        };

        const close = (isrefresh: boolean) => {
            console.log(isrefresh);
            if (isrefresh) {
                context.emit('close-sub-visible', {
                    isUploadVisible: false,
                    isrefresh: true,
                });
            } else {
                context.emit('close-sub-visible', {
                    isUploadVisible: false,
                    isrefresh: false,
                });
            }
        };

        // 确认编辑完成接口
        const upDateHandle = () => {
            // const pageDataStr = dataArray.join(",")

            const assArr = ref<any[]>([]);
            let iscanconfirm = true;
            queryListData.value.forEach((item, index) => {
                assArr.value.forEach((assitem, assindex) => {
                    if (assitem === item) {
                        message.error('AssignessArr duplicated.');
                        iscanconfirm = false;
                    } else {
                        assArr.value.push(item);
                    }
                });
            });

            if (iscanconfirm) {
                let assignessStr = '';
                const assignessArr = ref<any[]>([]);
                if (queryListData.value.length > 0) {
                    queryListData.value.forEach((item, index) => {
                        assignessArr.value.push(item.username);
                    });
                    assignessStr = assignessArr.value.join(',');
                    const params = {
                        programCode: editData.programCode,
                        offerTypeId: editData.offerTypeId,
                        assignee: assignessStr,
                    };
                    postCoverList(params).then((res) => {
                        console.log(359, res);
                        if (res.code === '0') {
                            message.success(res.message);
                            // context.emit('get-table-data')
                            close(true);
                        }
                    });
                } else {
                    message.error('At least choose one assignee.');
                }
            }

            /*const params = editData
      docEdit(params).then(res => {
        if (res.code === '0') {
          message.success(res.message)
          context.emit('get-table-data')
          close()
        }
      })*/
        };

        return {
            dogetSelectData,
            assignees,
            usersLst,
            nowName,
            handleAdd,
            dataArray,
            handleSelect,
            dogetlistData,
            queryListData,
            handleDelete,
            columns,
            editData,
            modal,
            close,
            upDateHandle,
        };
    },
});
