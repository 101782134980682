import { fetchPost, fetchPostPic,fetchGetMess,fetchGet} from '../index';
import axios from 'axios'
// 查询table数据接口
export const getQueryList = ( params = {}): Promise<any> => {
	return fetchPost('/claimapi/salesIncentiveClaim/claimApplicationList', params)
}

// 删除数据接口
export const deleteInfo = (docId = ''): Promise<any> => {
	return fetchPostPic(`/claimapi/salesIncentiveClaim/delete/${docId}`)
}

//获取年款
export const getModalYear = (): Promise<any> => {
	return fetchGet('/vapi/noAuth/get_property_lov?property=modelYear')
}

//获取车型分类
export const getTypeClass = (): Promise<any> => {
	return fetchGet('/vapi/noAuth/get_property_lov?property=typeClass')
}

//撤回申报
export const judgeCallBack = (params = {}): Promise<any> => {
	return fetchGetMess('/claimapi/salesIncentiveClaim/cancel',{params})
}

//上传撤回材料
export const uploadCallback = <T>(formData: FormData,params: any): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve,reject)=>{
        axios({
            method: "post",
            data: formData,
            url: "/claimapi/salesIncentiveClaim/delay/callback",
             params: params,
        }).then((res) => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        })
    })

}
//数据字典，查下拉框信息
export const getFindByGroupCode= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/claimDictionary/findByGroupCode',{params})
}