
import { getSelectProgramEnquiry ,getCheckingRoundList,exportPageApi,setClosingStatus,setApproveClosingStatus} from '@/API/claim/claimEnquiryProgram'
import { defineComponent, ref, reactive, toRefs, nextTick, createVNode} from "vue";
import { message   } from "ant-design-vue";
import { getUser } from "@/API/claim/delaerClaim/delaer";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
interface SearchDataType {
    bu: Array<string>;
    checkingRound: null|string;
    programStatus: null|string;
    programCode: null|string;
    programCodes: Array<string>;
    settingStatus: Array<string>;
}
interface TableType{
    programBuId: string;
    programBuName: string;
    programCode: string;
    checkingRound: string;
    programStatus: string;
    settingStatus: string;
    programBuSecondId: string;
    programBuSecondName: string;
    programId: string;
    programNameCn: string;
    programNameEn: string;
}
export default defineComponent({
    components:{
        ExclamationCircleOutlined
    },
    setup(){
        const visible = ref(false);
        const statusVisible = ref(false);
        const statisticsVisible = ref(false);
        const checked = ref('closing');

        const { state:vueState } = useStore();
        const user = (vueState.user as any).organization.entityCode;
        const isSICCO = user === 'SICCO' ? true : false;

        //存储一级BU
        const firstBUList = ref<string[]>([]);

        //判断用户角色按钮
        const userType = ref("");
        //是否有查询条件
        const hasQuery = ref(false);
        //checkingRound是否显示
        const isDisabled = ref(true);
        //输入框，用于控制焦点
        const input = ref()
        //显示的VIN数组
        const textNoArray = ref<string[]>([])
        //空占位
        const batchPlaceholder = ref(['', '', '', '', '', ''])
        //Modal的输入框
        const textContentNo = ref<string>("");
        const cleanEmptyEleArray = (array: Array<string>) => {
            if (array.length > 1) {
                    const tempArray = array.filter(item => item !== '')
                    tempArray.length || tempArray.push('')
                    return tempArray
                } else {
                    return array
            }
        }
        // 设置批量查询modal 的 占位div
        const setBatchPlaceholder = () => {
        if (textNoArray.value.length) {
                batchPlaceholder.value = []
            } else {
                batchPlaceholder.value = ['', '', '', '', '', '']
            }
        }

        const searchData = reactive<SearchDataType>({
            bu: [],
            checkingRound: null,
            programStatus: null,
            programCode: null,
            programCodes: [],
            settingStatus: [],
        });
        const searchDataTemplate = reactive<SearchDataType>({
            bu: [],
            checkingRound: null,
            programStatus: null,
            programCode: null,
            programCodes: [],
            settingStatus: [],
        });

        //bu数组
        const buList = ref<any[]>([]);
 
        // 批量查询
        const batchQuery = reactive({
            batchQueryTitle: '',
            batchQueryModal: false,
            batchQueryListTitle: ''
        })

        //checkingRound
        const checkingRoundList = ref<any[]>([]);

        //表格相关
        const total = ref(0);
        type Key = ColumnProps['key'];
        const state = reactive<{
            selectedRowKeys: Key[];
            loading: boolean;
        }>({
            selectedRowKeys: [], // Check here to configure the default column
            loading: false,
        });
        const onSelectChange = (selectedRowKeys: Key[]) => {
            state.selectedRowKeys = selectedRowKeys;
        };
        const dataSource = ref<TableType[]>([]);
        const columns = [
          {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
          },
          {
            title: 'BU',
            dataIndex: 'programBuName',
            key: 'programBuName',
          },
          {
            title: 'Checking Round',
            dataIndex: 'checkingRound',
            key: 'checkingRound',
          },
          {
            title: 'Program Status',
            dataIndex: 'programStatus',
            key: 'programStatus',
          },
          {
            title: 'Setting Status',
            dataIndex: 'settingStatus',
            key: 'settingStatus',
          },
        ];

        // 批量查询按钮事件
        const batchQueryEvent = () => {
            batchQuery.batchQueryModal = true
            batchQuery.batchQueryTitle = 'Program Code. Query'
            batchQuery.batchQueryListTitle = 'Program Code. List'
            textNoArray.value = cleanEmptyEleArray(searchData.programCodes)[0] === '' ? [] : cleanEmptyEleArray(searchData.programCodes)
            setBatchPlaceholder()
            const dom = document.getElementsByClassName('list-content')[0]
            nextTick(() => { 
                dom && dom.scrollTo(0, textNoArray.value.length * 36)
                input.value.focus()
            })
        }
        // 清除批量查询
        const clearBatchQuery = () => {
            searchData.programCodes = ['']
            textNoArray.value = cleanEmptyEleArray(searchData.programCodes)[0] === '' ? [] : cleanEmptyEleArray(searchData.programCodes)
            setBatchPlaceholder()
            searchData.programCode = null
            input.value.focus()
        }
        // 关闭批量查询Modal
        const closeBatchQueryModal = () => {
            searchData.programCode = cleanEmptyEleArray(searchData.programCodes)[0] as string
        }
        // 批量文本内容
        const textChangEvent = () => {
            if (textContentNo.value.length < 5) {
                message.error('Please paste into the text box from excel')
                textContentNo.value = ''
                return
            }
            textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g,",").split(','))
            const dom = document.getElementsByClassName('list-content')[0]
            textContentNo.value = ''
            searchData.programCodes = []
            searchData.programCodes = searchData.programCodes.concat(textNoArray.value)
            setBatchPlaceholder()
                nextTick(() => {
                    dom.scrollTo(0, dom.scrollHeight + 40)
                    input.value.focus()
            })
        }

        //设置状态 setStatus
        const setStatus=()=>{
            if (state.selectedRowKeys.length === 0){
                message.error('please select program');
                return 
            }

            for (let i=0;i<state.selectedRowKeys.length;i++){
                const one = dataSource.value[state.selectedRowKeys[i] as number];
                if (one.programStatus === 'Claiming'){
                    message.error('Data with Claiming cannot be selected')
                    return
                }
            }

            statusVisible.value = true;
        }

        const setApprove=()=>{
            debugger
            if (state.selectedRowKeys.length === 0){
                message.error('please select program');
                return 
            }
            let sign = false;
            for (let i=0;i<state.selectedRowKeys.length;i++){
                const one = dataSource.value[state.selectedRowKeys[i] as number];
                if (one.settingStatus.toLowerCase() !== 'sicco submitted'){
                    sign = true;
                    // return
                }
            }
            if (sign){
                message.error('Please select SICCO submitted program')
                return;
            }
            checked.value = 'approve'
            statisticsVisible.value = true;
        }



        //重置查询条件按钮
        const reset =()=>{
            searchData.bu = [];
            searchData.checkingRound = null;
            searchData.programStatus = null;
            searchData.programCode = null;
            searchData.programCodes = [];
            searchData.settingStatus =[];

            searchDataTemplate.bu = [];
            searchDataTemplate.checkingRound = null;
            searchDataTemplate.programStatus = null;
            searchDataTemplate.programCode = null;
            searchDataTemplate.programCodes = [];
            searchDataTemplate.settingStatus = [];
        }

        const getParams = ()=>{
            searchDataTemplate.bu = searchData.bu;
            searchDataTemplate.checkingRound = searchData.checkingRound;
            searchDataTemplate.programStatus = searchData.programStatus;
            searchDataTemplate.programCode = searchData.programCode;
            searchDataTemplate.programCodes = searchData.programCodes;
            searchDataTemplate.settingStatus = searchData.settingStatus;
            const param = {
                // buLst:searchDataTemplate.bu.length>0?searchDataTemplate.bu:firstBUList.value,
                buLst:searchDataTemplate.bu,
                checkingRound:searchDataTemplate.checkingRound,
                programStatus:searchDataTemplate.programStatus,
                programCode:(searchDataTemplate.programCodes.length>=1 && (searchDataTemplate.programCodes[0] !== ""))?'':searchDataTemplate.programCode,
                programCodeLst:(searchDataTemplate.programCodes.length === 1 && (searchDataTemplate.programCodes[0] === ""))?[]:searchDataTemplate.programCodes,
                settingStatus:searchDataTemplate.settingStatus,
            }
            console.log(param)

            return param
        }
        //查询表格
        const getTableData =()=>{
            const param = getParams();
            debugger
            if (param.buLst.length ===0 && param.checkingRound === null && param.programStatus === null && param.programCode === null && param.settingStatus.length===0){
                 message.error('Please select program');
                 return
            }
            if (param.buLst.length === 0 ){
                param.buLst = firstBUList.value;
            }   

            if (param.buLst.length>0 || param.checkingRound !== null || param.programStatus !== null || param.programCode !== null || param.settingStatus.length>0){
                
                getSelectProgramEnquiry(param).then(res => {
                    dataSource.value = res;
                    total.value = dataSource.value.length
                    if (res.length>0){
                        hasQuery.value = true;
                    }
                    
                })
                

            } else {
                message.error('Please select program');
                return
            }
        }
        const selectBUIDArray = ref<string[]>([]);
        //获取选择的bu
        const getSelectBU = (value: Array<string>)=>{
            selectBUIDArray.value = value;
        }

        //选择bu后，获取checkingRound
        const showCheckingRound =()=>{
            const value = selectBUIDArray.value;
            if (value === null || value.length === 0){
                isDisabled.value = true;
                return
            }
            const  buTL = new Set();
            for (let i=0;i<value.length;i++){
                const id = value[i];
                for (let j=0;j<buList.value.length;j++){
                    if (id === buList.value[j].id && buList.value[j].parentId === null){
                        buTL.add(id)
                    } 
                    if (id === buList.value[j].id && buList.value[j].parentId !== null) {
                        buTL.add(buList.value[j].parentId)
                    }
                }
            }
            const arr = Array.from(buTL);
            console.log(arr)
            getCheckingRoundList({buLst:arr}).then((res) => {
                console.log(res)
                checkingRoundList.value = res;
                isDisabled.value = false;
                searchData.checkingRound = null;
            })

            
            // getCheckingPlanDropList([value]).then((res) => {
            //     checkingRoundStatusArray.value = res;
            //     isDisabled.value = false;
            // })
        }

        getUser().then((res)=>{
            console.log(res.buList)
            const list = res.buList;
            const array = [];
            const valueArray = [];
            for (let i=0;i<list.length;i++){
                if (list[i].parentId === null){
                    array.push(list[i].nameEn)
                    valueArray.push(list[i])
                    firstBUList.value.push(list[i].id)
                }
            }
            for (let i=0;i<list.length;i++){
                if (list[i].parentId !== null && array.indexOf(list[i].nameEn)=== -1){
                    valueArray.push(list[i])
                }
            }
            buList.value = valueArray;
        })
                
        const showDrawer = (type: string)=>{
            userType.value = type;
            dataSource.value = [];
            checked.value = 'closing'
            reset();
            visible.value = true;
        }
        const handleSelectStatus = ()=>{
            statusVisible.value = false;
            statisticsVisible.value = true;
        }
        const examine=()=>{
            if (checked.value === 'approve'){
                const array = [];
                for (let i=0;i<state.selectedRowKeys.length;i++){
                    const index = state.selectedRowKeys[i] as number;
                    const one = dataSource.value[index];
                    array.push(one.programId)
                }
                console.log(array)
                setApproveClosingStatus({progDesignIds :array}).then((res)=>{
                    if (res.code === "0"){
                        message.success(res.message);
                        getTableData();
                        statisticsVisible.value= false
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                const array = [];
                for (let i=0;i<state.selectedRowKeys.length;i++){
                    const index = state.selectedRowKeys[i] as number;
                    const one = dataSource.value[index];
                    array.push(
                        // programBuId: one.programBuId,
                        // programBuName: one.programBuName,
                        // programBuSecondId: one.programBuSecondId,
                        // programBuSecondName: one.programBuSecondName,
                        // programCode: one.programCode,
                        one.programId
                        // programNameCn: one.programNameCn,
                        // programNameEn: one.programNameEn,
                    )
                }

                setClosingStatus({setProgramStatusLst:array},checked.value).then((res)=>{
                    if (res.code === "0"){
                        message.success(res.message);
                        getTableData();
                        statisticsVisible.value= false
                    } else {
                        message.error(res.message)
                    }
                })
            }
        }
        const exportTable=()=>{
            // console.log('导出')
             const param = getParams();
             console.log(param)
            exportPageApi(param);
        }
        return {
            input,textNoArray,batchPlaceholder,textContentNo,...toRefs(searchData),clearBatchQuery,textChangEvent,buList,isDisabled,
            showDrawer,visible,...toRefs(batchQuery),batchQueryEvent,closeBatchQueryModal,showCheckingRound,reset,userType,
            setStatus,getTableData,hasQuery,columns,dataSource,...toRefs(state),total,statusVisible,checked,handleSelectStatus
            ,statisticsVisible,examine,onSelectChange,getSelectBU,checkingRoundList,setApprove,exportTable,isSICCO
        }
    }
})
